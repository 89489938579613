<template>
    <div>
        <div class="main">
            <div class="nav">
                <div>
                    <img src="../../assets/images/login/ts.png" alt="">
                    正在登录【企业用户】
                </div>
            </div>
            <div class="main-content">
                <div class="left-img">
                    <img src="../../assets/images/login/login-img.png" alt="">
                </div>
                <div class="login">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="账号密码" name="first">
                            <el-form style="margin-top:40px" label-position="left" label-width="80px" :model="formLgin" @submit.native.prevent>
                                <el-form-item label="用户名:">
                                    <el-input placeholder="请输入账号或手机号" v-model="formLgin.accountKey"></el-input>
                                </el-form-item>
                                <el-form-item label="密码:">
                                    <el-input type="password" placeholder="请输入账户密码" v-model="formLgin.accountPsd" autocomplete="off"></el-input>
                                </el-form-item>
                                <div class="forget" @click="goEnterForgot">忘记密码</div>
                                <button class="submit" @click="perLogin">登录</button>
                                <div class="register " @click="goEnterRegister">免费注册</div>
                            </el-form>
                        </el-tab-pane>
                        <el-tab-pane label="手机验证" name="second">
                            <el-form style="margin-top:40px" label-position="left" label-width="100px" :model="from" @submit.native.prevent>
                                <el-form-item label="手机号:">
                                    <el-input placeholder="请输入手机号" v-model="from.phone"></el-input>
                                </el-form-item>
                                <el-form-item label="图形验证码:">
                                    <el-input v-model="from.smaCode"></el-input>
                                    <div class="verification">
                                        <Code ref="ref_validateCode" @change="changeCode" />
                                        <div class="text" @click="changeImg">看不清？<span>换张图</span></div>
                                    </div>
                                </el-form-item>
                                <el-form-item label="手机验证码:">
                                    <div class="code">
                                        <input placeholder="请输入验证码" v-model="from.smsCode" type="text">
                                        <button @click="getCode" v-show="!countDownShow">发送验证码</button>
                                        <button @click="settime" :disabled="isdisabledFn" v-show="countDownShow">{{countDown}}</button>
                                    </div>
                                </el-form-item>

                                <button class="submit" @click="compare">登录</button>
                                <div class="register" @click="goEnterRegister">免费注册</div>
                            </el-form>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Code from "../../components/code.vue";

export default {
    components: {
        Code
    },
    data() {
        return {
            activeName: 'first',
            formLgin: {
                accountKey: '',
                accountPsd: ''
            },
            from: {
                phone: '',
                smsCode: '',
            },
            smaCode: '',
            countDownShow: false,
            count: 60,
            countDown: '',
            isdisabledFn: false,
            checkCode: "",

        };
    },

    mounted() {
    },

    methods: {
        goEnterRegister() {
            this.$router.push({ path: '/login' })
        },
        // 忘记密码
        goEnterForgot() {
            this.$router.push({ path: '/enterForgot' })
        },


        handleClick(tab, event) {
            console.log(tab, event);
        },
        getCode() {
            // const email = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
            if (this.from.phone == '') {
                return alert('请输入手机号')
            }
            this.$util.post('sys-sms/sms-send', {
                phone: this.from.phone,
                action: 'Other'
            }).then(res => {
                if (res.code == 200) {
                    this.countDownShow = true
                    this.settime()
                }
            })

        },
        // 账号密码登录
        perLogin() {
			if(this.formLgin.accountKey == ''){
				return this.$message.error('请输入账号或手机号');
			}
			else if(this.formLgin.accountPsd == ''){
				return this.$message.error('请输入账户密码');
			}
            this.$util.post('/user/login', this.formLgin).then(res => {
                if (res.code == 200) {
                    sessionStorage.setItem("token", res.token);
                    localStorage.setItem("token", res.token)
                    this.$router.push({ path: '/home' })
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        settime() {
            if (this.count == 0) {
                this.count = 60;
                this.countDownShow = false
                return false;
            } else {
                this.countDownShow = true
                this.isdisabledFn = true
                this.countDown = '重新发送' + "(" + this.count + ")"
                this.count--;
            }
            setTimeout(() => {
                this.settime();
            }, 1000);
        },
        changeCode(value) {
            this.checkCode = value;
        },
        compare() {
			if (this.from.phone == "") {
			    return this.$message.error('请输入手机号');
			}
			if (this.from.smsCode == "") {
			    return this.$message.error('请输入验证码');
			}
			
            if (this.smaCode == "") {
                return this.$message.error('请输入图形验证码');
            }
            if (this.smaCode.toUpperCase() === this.checkCode) {
                this.$util.post('/user/loginByPhone', this.from).then(res => {
                    console.log(res);
                    if (res.code == 200) {
                        sessionStorage.setItem("token", res.token);
                        localStorage.setItem("token", res.token);
                        this.$router.push({ path: '/home' })
                    } else {
                        this.$message.error(res.message);
                    }
                })
            } else {
                this.$message.error('图形验证码错误');
                this.smaCode = "";
                this.$refs["ref_validateCode"].draw();
            }
        },
        changeImg() {
            this.$refs["ref_validateCode"].draw();
        }

    },
};
</script>

<style lang="scss" scoped>
.main {
    background: url("../../assets/images/login/login-bg.png") round;
    background-color: #e40012;
    // height: 550px;
    padding-bottom: 90px;
    .main-content {
        width: 1200px;
        margin: 0 auto 0;
        padding-top: 54px;
        display: flex;
        align-items: center;

        .left-img {
            width: 405px;
            height: 429px;
            margin-right: 250px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.nav {
    height: 50px;
    background: #e5f4fc;
    color: #2ea7e0;
    display: flex;
    align-items: center;
    div {
        display: flex;
        align-items: center;
        width: 1200px;
        font-size: 16px;
        margin: 0 auto;
        img {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }
    }
}
.login {
    width: 530px;
    padding: 30px;
    // height: 415px;
    background: #fff;
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
    width: 90px !important;
}
/deep/.el-tabs__item {
    font-size: 22px;
    font-weight: bold;
    color: #666666;
    // margin-right: 40px;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
/deep/.el-tabs__nav-wrap::after {
    display: none !important;
}
/deep/.el-form-item__label {
    font-size: 16px;
    color: #999999;
}
/deep/.el-input__inner {
    height: 50px;
    font-size: 16px;
}
.forget {
    font-size: 14px;
    color: #e40012;
    text-align: right;
    cursor: pointer;
    font-weight: bold;
}
.submit {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    background: #e40012;
    font-size: 18px;
    color: #ffff;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
.register {
    font-size: 14px;
    color: #666666;
    text-align: right;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
}
.code {
    height: 48px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    input {
        flex: 1;
        height: 100%;
        border: none;
        outline: none;
        font-size: 16px;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.verification {
    display: flex;
    margin-top: 10px;
    align-items: center;
    .text {
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        margin-left: 10px;
        span {
            color: #e40012;
        }
    }
}
</style>